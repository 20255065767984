import React, { createContext } from 'react';
import io from 'socket.io-client';

const socket = io(process.env.REACT_APP_SERVER_URL || 'localhost:1234');

export const SocketIOContext = createContext(socket);

interface Props {
  children: React.ReactNode;
}

export const SocketIOContextProvider = ({ children }: Props) => (
  <SocketIOContext.Provider value={socket}>{children}</SocketIOContext.Provider>
);
