import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { SocketIOContext } from '../contexts/SocketIOContext';
import { Helmet } from 'react-helmet-async';
import { Fireworks } from '@fireworks-js/react';
import {
  Avatar,
  AvatarBadge,
  Box,
  Container,
  Heading,
  Stack,
  Input,
  Icon,
  Button,
  FormControl,
  FormErrorMessage,
  Text,
  UnorderedList,
  ListItem,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  Tooltip,
  Badge,
  Wrap,
  WrapItem,
  // useToast,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
} from '@chakra-ui/core';
import { useForm } from 'react-hook-form';
import { BiChevronDown } from 'react-icons/bi';
import { CgCrown } from 'react-icons/cg';
import { useHistory } from 'react-router-dom';

interface User {
  id: string;
  metadata: {
    nickname: string;
    vote?: number;
    mod: { enabled: boolean; showResults: boolean; allowVote: false };
    roomId: string;
  };
}

const allEqual = (arr: number[]) => arr.every(val => val === arr[0]);

const PlanningRoom = () => {
  let { roomId } = useParams<{ roomId: string }>();
  const socket = useContext(SocketIOContext);
  const [localUsers, setLocalUsers] = useState<User[]>([]);
  const [nickname, setNickname] = useState<string>();
  const { handleSubmit, register, errors } = useForm();
  // const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();

  useEffect(() => {
    // @ts-ignore
    // analytics.group('abyrd-test-1');
    socket.emit('room-joined', roomId, localStorage.getItem('planningUser'));
    socket.on('user-change', (id: string, users: User[]) => {
      if (id === roomId) {
        setLocalUsers(users);
        const myUser = users.filter(user => user.id === socket.id)[0];
        localStorage.setItem('planningUser', JSON.stringify(myUser.metadata));
        // TODO: optimize this re-renders are crazy right now
        // toast({
        //   title: 'Room updated.',
        //   description: 'Someone has done something, lol...',
        //   status: 'success',
        //   duration: 1000,
        //   isClosable: true,
        // });
        if (myUser.metadata.nickname === 'unknown') {
          onOpen();
        }
      }
    });
    socket.on('connect', () => {
      socket.emit('room-joined', roomId, localStorage.getItem('planningUser'));
    });
    // }, [socket, roomId, toast, onOpen]);
  }, [socket, roomId, onOpen]);

  function handleVoteOnClick(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    // @ts-ignore
    analytics.track('Voted', {
      vote: Number(event.currentTarget.value),
      roomId
    });
    socket.emit('set-vote', Number(event.currentTarget.value), roomId);
  }

  const usersVoted = localUsers.filter(
    user =>
      user.metadata.vote &&
      (!user.metadata.mod.enabled ||
        (user.metadata.mod.enabled && user.metadata.mod.allowVote))
  );
  const votes = usersVoted.map(user => user.metadata.vote!);
  const [user] = localUsers.filter(user => user.id === socket.id);
  const voters = localUsers.filter(
    user =>
      !user.metadata.mod.enabled ||
      (user.metadata.mod.enabled && user.metadata.mod.allowVote)
  );
  const mods = localUsers.filter(user => user.metadata.mod.enabled);
  const voteValues = [1, 2, 3, 5, 8, 13];

  return (
    <Box>
      <Helmet>
        <title>{`(${localUsers.length}) Planning: ${roomId}`}</title>
      </Helmet>
      <Container>
        <Heading fontSize="lg">Planning Room: {roomId}</Heading>
        <Stack spacing={8}>
          {user?.metadata.mod.enabled ? (
            <Box p={5} shadow="md" borderWidth="1px">
              <Heading fontSize="md">Moderation</Heading>
              <Wrap direction="row" spacing={4} align="center">
                <WrapItem>
                  <Button onClick={() => socket.emit('set-new-vote', roomId)}>
                    New Vote
                  </Button>
                </WrapItem>
                <WrapItem>
                  <Button
                    onClick={() =>
                      socket.emit('set-show-results', true, roomId)
                    }>
                    Show Results
                  </Button>
                </WrapItem>
                <WrapItem>
                  <Button
                    onClick={() => {
                      // @ts-ignore
                      // analytics.track('Mod vote', {
                      //   allowVote: !user?.metadata.mod.allowVote,
                      //   roomId,
                      // });
                      socket.emit(
                        'set-mod-vote',
                        !user?.metadata.mod.allowVote,
                        roomId
                      );
                    }}>
                    {`${
                      user?.metadata.mod.allowVote ? 'Disable' : 'Enable'
                    } Mod Vote`}
                  </Button>
                </WrapItem>
                <WrapItem>
                  <Button onClick={() => socket.emit('set-mod', false, roomId)}>
                    Stop Moderation
                  </Button>
                </WrapItem>
              </Wrap>
              {!user?.metadata.mod.allowVote ||
              mods.some((mod: User) => mod.metadata.mod.showResults) ? (
                <UnorderedList my="4">
                  {usersVoted.map(votedUser => (
                    <ListItem>
                      <Text>{`${votedUser.metadata.nickname} voted ${votedUser.metadata.vote}`}</Text>
                    </ListItem>
                  ))}
                </UnorderedList>
              ) : null}
            </Box>
          ) : null}
          <Box p={5} shadow="md" borderWidth="1px">
            <Heading fontSize="md">{`Participants (${localUsers.length})`}</Heading>
            <Wrap direction="row">
              {localUsers.map(avatarUser => (
                <WrapItem>
                  <Tooltip
                    hasArrow
                    label={avatarUser.metadata.nickname}
                    openDelay={500}>
                    <Avatar name={avatarUser.metadata.nickname}>
                      {avatarUser.metadata.mod.enabled &&
                      avatarUser.metadata.mod.allowVote &&
                      avatarUser.metadata.vote ? (
                        <AvatarBadge
                          as={CgCrown}
                          boxSize="1.25em"
                          bg="green.500"
                        />
                      ) : avatarUser.metadata.mod.enabled ? (
                        <AvatarBadge
                          as={CgCrown}
                          boxSize="1.25em"
                          bg="yellow.400"
                        />
                      ) : avatarUser.metadata.vote ? (
                        <AvatarBadge bg="green.500" boxSize="1.25em" />
                      ) : null}
                    </Avatar>
                  </Tooltip>
                </WrapItem>
              ))}
            </Wrap>
            <Wrap my="4" direction="row">
              {voteValues.map(score => (
                <WrapItem>
                  <Button
                    border={user?.metadata.vote === score ? '2px' : 'none'}
                    value={score}
                    onClick={handleVoteOnClick}
                    isDisabled={
                      user?.metadata.mod.enabled &&
                      !user?.metadata.mod.allowVote
                    }>
                    {score}
                  </Button>
                </WrapItem>
              ))}
              <WrapItem>
                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<Icon as={BiChevronDown} />}>
                    Actions
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={onOpen}>Change name</MenuItem>
                    {mods.length === 0 ? (
                      <MenuItem
                        onClick={() => socket.emit('set-mod', true, roomId)}>
                        Start Moderation
                      </MenuItem>
                    ) : null}
                    <MenuItem onClick={() => history.push('/')}>Exit room</MenuItem>
                  </MenuList>
                </Menu>
              </WrapItem>
            </Wrap>
            <Text>
              {voters.length
                ? `${votes.length} of ${voters.length} votes`
                : 'No voters'}
            </Text>
          </Box>
          {mods.some((mod: User) => mod.metadata.mod.showResults) ? (
            <>
              <Box p={5} shadow="md" borderWidth="1px">
                <Heading fontSize="md">Results</Heading>
                <Wrap direction="row">
                  {voteValues.map(score => {
                    const numVotes = votes.filter(vote => vote === score)
                      .length;
                    return (
                      <WrapItem>
                        <Badge
                          colorScheme={numVotes > 0 ? 'green' : 'gray'}
                          fontSize="md">{`${score} point${
                          score !== 1 ? 's' : ''
                        } ${numVotes > 0 ? `(${numVotes})` : ''}`}</Badge>
                      </WrapItem>
                    );
                  })}
                </Wrap>
                {votes.length > 0 ? (
                  <Stat mt="4">
                    <StatLabel>Average</StatLabel>
                    <StatNumber>
                      {(
                        votes.reduce((pv, cv) => pv + cv) / votes.length
                      ).toFixed(1)}{' '}
                      points
                    </StatNumber>
                    <StatHelpText>
                      <StatArrow type="decrease" />
                      {Math.min(...votes)}
                      <StatArrow ml="2" type="increase" />
                      {Math.max(...votes)}
                    </StatHelpText>
                  </Stat>
                ) : null}
              </Box>
              {votes.length > 0 && allEqual(votes) && (
                <Fireworks
                  options={{
                    opacity: 0.5,
                  }}
                  style={{
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    position: 'fixed',
                    background: 'inherit',
                    zIndex: -1,
                  }}
                />
              )}
            </>
          ) : null}
        </Stack>
      </Container>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <form
            onSubmit={handleSubmit(() => {
              socket.emit('set-name', nickname, roomId);
              // @ts-ignore
              analytics.identify(`${user.id}@gmail.com`, {
                ...user
              });
              onClose();
            })}
            autoComplete="off">
            <ModalHeader>Enter a nickname</ModalHeader>
            <ModalBody>
              <FormControl isInvalid={!!errors.participantName}>
                <Input
                  name="participantName"
                  data-lpignore="true"
                  placeholder="Participant Name"
                  ref={register({
                    required: 'Required',
                  })}
                  isInvalid={!!errors.participantName}
                  onChange={e => {
                    setNickname(e.currentTarget.value);
                  }}
                />
                <FormErrorMessage>
                  {errors.participantName?.message}
                </FormErrorMessage>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button type="submit" colorScheme="blue" mr={3}>
                Continue
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default PlanningRoom;
