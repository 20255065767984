import React from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import PlanningRoom from './views/PlanningRoom';
import { useForm } from 'react-hook-form';
import {
  Flex,
  Box,
  Container,
  Heading,
  Input,
  Button,
  FormControl,
  FormErrorMessage,
  IconButton,
  Icon,
  useColorMode,
  Image,
} from '@chakra-ui/core';
import { HiSun, HiMoon } from 'react-icons/hi';

function App() {
  const history = useHistory();
  const { handleSubmit, register, errors } = useForm();
  const onSubmit = ({ roomId }: { [x: string]: string }) => {
    // @ts-ignore
    // analytics.track('Created room', {
    //   roomId
    // })
    history.push(`/${roomId}`);
  };
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <div className="App">
      <Box boxShadow="md">
        <Container>
          <Flex w="100%" align="center">
            <Heading fontSize="xl" my="2">
              Massive Nerd's Planning Poker
            </Heading>
            <IconButton
              onClick={toggleColorMode}
              ml="auto"
              aria-label="Toggle color mode"
              icon={
                colorMode === 'light' ? (
                  <Icon as={HiMoon} />
                ) : (
                  <Icon as={HiSun} />
                )
              }
            />
          </Flex>
        </Container>
      </Box>
      <Switch>
        <Route path="/:roomId">
          <PlanningRoom />
        </Route>
        <Route path="/">
          <Container mt="5">
            <Flex justify="center">
              <Image
                boxSize={['307.2px', '409.6px', '512px']}
                objectFit="cover"
                src="./logo512.png"
                alt="Massive Nerds logo"
              />
            </Flex>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <FormControl isInvalid={!!errors.roomId}>
                <Input
                  name="roomId"
                  data-lpignore="true"
                  placeholder="Enter Room Name"
                  ref={register({
                    required: 'Required',
                  })}
                  isInvalid={!!errors.roomId}
                />
                <FormErrorMessage>{errors.roomId?.message}</FormErrorMessage>
              </FormControl>
              <Flex justify="flex-end">
                <Button mt={4} type="submit">
                  Submit
                </Button>
              </Flex>
            </form>
          </Container>
        </Route>
      </Switch>
    </div>
  );
}

export default App;
