import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { SocketIOContextProvider } from './contexts/SocketIOContext';
import { HelmetProvider } from 'react-helmet-async';
import { ChakraProvider } from '@chakra-ui/core';

Sentry.init({
  dsn: "https://4082f630d1ab47b48e06ac1f40aea521@o480686.ingest.sentry.io/5528169",
  integrations: [
    new Integrations.BrowserTracing(),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <HelmetProvider>
        <SocketIOContextProvider>
          <ChakraProvider>
            <App />
          </ChakraProvider>
        </SocketIOContextProvider>
      </HelmetProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
